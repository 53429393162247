import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Clean Grip Deadlifts 8-8-8@ 75% of Clean 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`10-Deadlifts (155/105)`}</p>
    <p>{`10-Box Jumps (24/20)`}</p>
    <p>{`10-Pushups`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`3 rounds for time of:`}</p>
    <p>{`15-T2B`}</p>
    <p>{`15-KB SDHP’s (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Thursday is our Annual Free Thanksgiving Day WOD at 9:00am at
The Ville!  Both gyms will be closed other than the 9:00am Ville class
and the 6am class at East on Friday will be cancelled.  All other
classes are on as scheduled.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      